(function(w,d,s,c,f,n,t,g,a,b,l){w['PactSafeObject']=n;w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},w[n].on=function(){(w[n].e=w[n].e||[]).push(arguments)},w[n].once=function(){(w[n].eo=w[n].eo||[]).push(arguments)},w[n].off=function(){(w[n].o=w[n].o||[]).push(arguments)},w[n].t=1*new Date(),w[n].l=0;a=d.createElement(s);b=d.getElementsByTagName(s)[0];a.async=1;a.src=c;a.onload=a.onreadystatechange=function(){w[n].l=1};a.onerror=a.onabort=function(){w[n].l=0};b.parentNode.insertBefore(a,b);setTimeout(function(){if(!w[n].l&&!w[n].loaded){w[n].error=1;a=d.createElement(s);a.async=1;a.src=f;a.onload=a.onreadystatechange=function(){w[n].l=1};a.onerror=a.onabort=function(){w[n].l=0};b.parentNode.insertBefore(a,b);l=function(u,e){try{e=d.createElement('img');e.src='https://d3r8bdci515tjv.cloudfront.net/error.gif?t='+w[n].t+'&u='+encodeURIComponent(u);d.getElementsByTagName('body')[0].appendChild(e)}catch(x){}};l(c);setTimeout(function(){if(!w[n].l&&!w[n].loaded){w[n].error=1;if(g&&'function'==typeof g){g.call(this);}l(f)}},t)}},t)})(window,document,'script','https://vault.pactsafe.io/ps.min.js','https://d3l1mqnl5xpsuc.cloudfront.net/ps.min.js','_ps',4000);
      // We'll need a couple of things to get started from Ironclad Clickwrap.
      function clickwrapData(SiteAccessId,GroupKey)
      {
        var siteAccessId = SiteAccessId; // A Clickwrap Site Access ID
        var groupKey = GroupKey; // A Clickwrap Group Key.
        // Creates a Site object with the a Clickwrap Site Access ID.
        _ps('create', siteAccessId, {
           // test_mode: true, // Allows you to clear test data from the Ironclad Clickwrap web app.
        });
       // _ps("create", "f5533e60-a6e9-4626-aa58-1f45eda2d516");
    
        // Since we're testing, we can enable debugging
        // which will log events to console. You'll want to
        // set this to false in a production environment.
        _ps.debug = false;
  
        // Options set on the Clickwrap Group.
        var groupOptions = {
          container_selector: 'clickwrapContainer', // ID of where we want the clickwrap to load in the page.
          display_all: true, // Always display the contracts, even if previously signed by the Signer.
          signer_id_selector: 'email', // Uses the email input field value as the Signer ID and listen to the field.
        }
        _ps('load', groupKey, groupOptions);
        
      }
    
      function ClickwrapSubmitData(firstName,lastName,companyName,email,groupKey) {
        //alert("blockSubmission");
        //_ps('load', groupKey, groupOptions);
        _ps("set", "signer_id", email);
        _ps('set', 'custom_data',
          {first_name:firstName, last_name: lastName, company_name: companyName, site: "Configurator"});
          //Use that custom_data to update the Signer Record
         _ps('send', 'updated');
         _ps(groupKey + ':send', 'agreed', {
          disable_sending: false, // We have to revert to allow sending with the snippet here.
          event_callback: function(err, eventType, group, request) {
            if (err) {
              // Something went wrong with sending the event.
              alert('Uh oh, something went wrong. Please try submitting again.'); // Alert the user
              return;
            }
          }
        });
          }